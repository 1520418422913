import i18n from '@/vue-app/plugins/i18n';
import TYPES from '@/types';
import Vue from 'vue';
import { v4 } from 'uuid';

import { parseCurrencyToNumber } from '@/vue-app/utils/parse-currency-to-number';

// Application
import {
  SearchAllianzRecurringContributionsQuery,
} from '@/modules/my-investment/allianz/recurring-contributions/application/queries';
import CreateCustomInvestorGoalWithContributionsCommand
  from '@/modules/flagship/custom-investor-goal/application/command/create-custom-investor-goal-with-contributions-command';
import CreateRetirementInvestorGoalWithContributionsCommand
  from '@/modules/flagship/retirement-investor-goal/application/commands/create-retirement-investor-goal-with-contributions-command';
import GetRetirementInvestorGoalQuery
  from '@/modules/flagship/retirement-investor-goal/application/queries/get-retirement-investor-goal-query';
import CreateBucketInvestorGoalWithContributionsCommand
  from '@/modules/flagship/bucket-investor-goal/application/command/create-bucket-investor-goal-with-contributions-command';
import AccessMoreStrategiesStateManagerService
  from '@/modules/my-investment/allianz/access-more-strategies/application/services/access-more-strategies-state-manager-service';

// Domain
import {
  CreateInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/create-investor-goal-state-manager';
import {
  CustomInvestorGoalWithContributionsDto,
} from '@/modules/flagship/custom-investor-goal/domain/dtos/custom-investor-goal-with-contributions-dto';
import {
  CustomInvestorGoalContributionDto,
} from '@/modules/flagship/custom-investor-goal/domain/dtos/custom-investor-goal-contribution-dto';
import {
  RetirementInvestorGoalWithContributionsDto,
} from '@/modules/flagship/retirement-investor-goal/domain/dtos/retirement-investor-goal-with-contributions-dto';
import {
  StateManager as ContributionsStateManager,
} from '@/modules/my-investment/allianz/recurring-contributions/domain/state/state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { DatetimeValue } from '@/modules/shared/domain/value-objects/datetime-value';

export default class CreateAndLinkWithWealthGoalService {
  @Inject(TYPES.GET_ALLIANZ_RECURRING_CONTRIBUTIONS_QUERY)
  private readonly get_allianz_recurring_contributions_query!:
    SearchAllianzRecurringContributionsQuery;

  @Inject(TYPES.CREATE_CUSTOM_INVESTOR_GOAL_V2_COMMAND)
  private readonly create_custom_investor_goal_v2!:
    CreateCustomInvestorGoalWithContributionsCommand;

  @Inject(TYPES.CREATE_RETIREMENT_INVESTOR_GOAL_V2_COMMAND)
  private readonly create_retirement_invest_command!:
    CreateRetirementInvestorGoalWithContributionsCommand;

  @Inject(TYPES.CREATE_BUCKET_INVESTOR_GOAL_V2_COMMAND)
  private readonly create_bucket_investor_goal!: CreateBucketInvestorGoalWithContributionsCommand;

  @Inject(TYPES.CREATE_INVESTOR_GOAL_STATE_MANAGER)
  readonly create_investor_goal_state_manager!: CreateInvestorGoalStateManager;

  @Inject(TYPES.ACCESS_MORE_STRATEGIES_STATE_MANAGER_SERVICE)
  private readonly manager_service!: AccessMoreStrategiesStateManagerService;

  @Inject(TYPES.ALLIANZ_RECURRING_CONTRIBUTIONS_STATE_MANAGER)
  private readonly contributions_state_manager!:
    ContributionsStateManager;

  @Inject(TYPES.GET_RETIREMENT_INVESTOR_GOAL_QUERY)
  private readonly get_retirement_investor_query!: GetRetirementInvestorGoalQuery;

  @Inject(TYPES.DATETIME_VALUE)
  datetimeValue!: DatetimeValue;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  private readonly view!: Vue;

  private readonly customer_id = sessionStorage.getItem('user_id');

  private readonly error_allianz_account_not_exists = `Error customer_id:${this.customer_id}, not exists`;

  readonly i18n_namespace = 'components.flagship.flagship-link-goals.step-schedule-payment-date-wealth';

  first_contribution_id = v4()

  second_contribution_id = v4()

  investor_goal_state = this.create_investor_goal_state_manager.state;

  budget_goal_state = this.manager_service.getAccessMoreStrategiesState();

  contributions_state = this.contributions_state_manager.state;

  translate = (value: string, options?: Record<string, string | number>) => {
    if (options) {
      return i18n.tc(`${this.i18n_namespace}.${value}`, 0, options) as string;
    }
    return i18n.t(`${this.i18n_namespace}.${value}`) as string;
  }

  setRetirementInvestorGoalIdIfAlreadyExists = async () => {
    try {
      const retirement_investor = await this.get_retirement_investor_query.execute(true);
      this.investor_goal_state.investor_goal.id = retirement_investor.id;
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.get_retirement_investor'));
      }
    }
  };

  formatContributions = (): Array<CustomInvestorGoalContributionDto> => {
    const contributions: Array<CustomInvestorGoalContributionDto> = [];
    if (this.investor_goal_state.first_goal_contribution.set) {
      if (this.contributions_state.item.contributions.length) {
        this.first_contribution_id = this.contributions_state.item.contributions[0].id;
      }
      contributions.push({
        id: this.first_contribution_id,
        amount: this.investor_goal_state.first_goal_contribution.amount,
        collection_day: this.investor_goal_state.first_goal_contribution.collection_day,
      });
    }
    if (this.investor_goal_state.second_goal_contribution.set) {
      if (this.contributions_state.item.contributions.length === 2) {
        this.second_contribution_id = this.contributions_state.item.contributions[1].id;
      }
      contributions.push({
        id: this.second_contribution_id,
        amount: this.investor_goal_state.second_goal_contribution.amount,
        collection_day: this.investor_goal_state.second_goal_contribution.collection_day,
      });
    }
    return contributions;
  }

  createCustomInvestorGoal = async () => {
    const contributions = this.formatContributions();
    const payload: CustomInvestorGoalWithContributionsDto = {
      id: this.investor_goal_state.investor_goal.id,
      goal_name: this.investor_goal_state.investor_goal.goal_name,
      desired_amount: this.investor_goal_state.investor_goal.desired_amount,
      fixed_time_adjusted: this.investor_goal_state.investor_goal.fixed_time_adjusted.toFixed(2),
      investor_goal: {
        investor_profile_id: this.investor_goal_state.investor_goal.investor_profile_id,
        initial_amount: this.investor_goal_state.investor_goal.initial_amount,
        accumulated_amount: this.investor_goal_state.investor_goal.accumulated_amount,
        monthly_required_amount: this.investor_goal_state.investor_goal.monthly_required_amount,
        initial_investment_date: this.investor_goal_state.investor_goal.initial_investment_date,
        final_investment_date: this.investor_goal_state.investor_goal.final_investment_date,
      },
      custom_goal_type: this.investor_goal_state.custom_investor_goal.custom_goal_type,
      link_information: {
        active_goal_amount: 0,
      },
      contributions,
    };
    if (!this.investor_goal_state.investor_goal.already_created) {
      await this.create_custom_investor_goal_v2.execute(payload);
    }
  }

  createRetirementInvestorGoal = async () => {
    await this.setRetirementInvestorGoalIdIfAlreadyExists();
    const contributions = this.formatContributions();
    const payload: RetirementInvestorGoalWithContributionsDto = {
      id: this.investor_goal_state.investor_goal.id,
      retirement_age_adjusted: this.investor_goal_state.retirement_fund
        .retirement_age_adjusted,
      issued_age: this.investor_goal_state.retirement_fund.issued_age,
      retirement_range_adjusted: (this.investor_goal_state.retirement_fund
        .retirement_range_adjusted * 12).toString(),
      pension_range_adjusted: (this.investor_goal_state
        .retirement_fund.pension_range_adjusted * 12),
      monthly_pension_adjusted: this.investor_goal_state.retirement_fund.calculated_pension
        .toString(),
      investor_goal: {
        investor_profile_id: this.investor_goal_state.investor_goal.investor_profile_id,
        initial_amount: this.investor_goal_state.investor_goal.initial_amount,
        initial_amount_adjusted: this.investor_goal_state.investor_goal.initial_amount_adjusted,
        accumulated_amount: this.investor_goal_state.investor_goal.accumulated_amount,
        monthly_required_amount: this.investor_goal_state.investor_goal.monthly_required_amount
          .toString(),
        initial_investment_date: this.investor_goal_state.investor_goal.initial_investment_date,
        final_investment_date: this.investor_goal_state.investor_goal.final_investment_date,
      },
      link_information: {
        active_goal_amount: 0,
      },
      contributions,
    };

    await this.create_retirement_invest_command.execute(payload);
  }

  createGoal = async () => {
    try {
      this.investor_goal_state.is_loading = true;
      if (this.investor_goal_state.investor_goal_type) {
        if (this.investor_goal_state.investor_goal_type !== 'retirement_fund') {
          await this.createCustomInvestorGoal();
        } else {
          await this.createRetirementInvestorGoal();
        }
        await this.reloadAllianzData();
        if (!this.investor_goal_state.investor_goal.already_created) {
          if (this.investor_goal_state.available_balance > 1) {
            this.investor_goal_state.goal_created_and_linked = true;
          } else {
            this.investor_goal_state.goal_linked_successfully = true;
          }
        }
      } else {
        await this.createBucket();
      }
      return true;
    } catch (error) {
      this.message_notifier.showErrorNotification(this.translate('errors.update_payment_date'));
      return false;
    } finally {
      this.investor_goal_state.is_loading = false;
    }
  }

  reloadAllianzData = async () => {
    await this.get_allianz_recurring_contributions_query
      .internalExecute(true);
  }

  createBucket = async (): Promise<boolean> => {
    try {
      this.budget_goal_state.is_loading = true;
      const contributions = this.formatContributions();
      const initial_amount = parseCurrencyToNumber(
        this.budget_goal_state.initial_balance,
      );
      const payload = {
        investor_goal_id: this.budget_goal_state.investor_goal.id,
        initial_investment_date: this.datetimeValue.create().toString(),
        investment_product_fund_type_id: this.budget_goal_state.strategy.id,
        initial_amount,
        term: this.budget_goal_state.investor_goal.term.toFixed(2),
        goal_name: this.budget_goal_state.strategy.name,
        was_accepted: this.budget_goal_state.customer_agreement.was_accepted,
        agreement_type_id: this.budget_goal_state.customer_agreement.agreement_type_id || null,
        accepted_on: this.budget_goal_state.customer_agreement.accepted_on || null,
        contributions,
      };
      await this.create_bucket_investor_goal.internalExecute(payload);
      this.budget_goal_state.successfully_created = true;
      return true;
    } catch (error) {
      this.message_notifier.showErrorNotification(this.translate('errors.complete_flow'));
      return false;
    } finally {
      this.budget_goal_state.is_loading = false;
    }
  }
}
